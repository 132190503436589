@tailwind base;
@tailwind utilities;

@font-face {
  font-family: "simplonmono";
  src: url("./fonts/simplonmono-regular-webfont.woff2") format("woff2"),
       url("./fonts/simplonmono-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "whyte";
  src: url("./fonts/whyte-book-webfont.woff2") format("woff2"),
       url("./fonts/whyte-book-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "whyte";
  src: url("./fonts/whyte-regular-webfont.woff2") format("woff2"),
       url("./fonts/whyte-regular-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gtalpinafine";
  src: url("./fonts/gtalpinafine-regular-webfont.woff2") format("woff2"),
       url("./fonts/gtalpinafine-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gtalpinafine";
  src: url("./fonts/gtalpinafine-regularitalic-webfont.woff2") format("woff2"),
       url("./fonts/gtalpinafine-regularitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

.person-image:before {
  content: '';
  padding-top: 68.639053%;
  display: block;
}

.footer-image:before {
  content: '';
  padding-top: 100%;
  display: block;
}
